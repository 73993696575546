<template>
  <div>

    <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">

      <div class="card-header cursor-pointer">

        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">List of Samples</h3>
        </div>
        <router-link
          to="/samples/add"
          class="btn btn-primary align-self-center ms-auto me-2"
          >Add Sample</router-link>
      </div>
      <div class="card-body p-7">

        <Table :columns="columns" ref="table" :action="actions.SEARCH_SAMPLES">

          <template v-slot:type><div>SAMPLE</div></template>

          <template v-slot:actions="slotProps">

            <div>

              <div class="btn-group" role="group">

                <router-link :to="'/samples/edit/'+slotProps.row.id" class="btn btn-sm btn-light-warning font-weight-bold"><i class="fs-1-3 la la-edit"></i></router-link>

                <DeleteRecord :id="slotProps.row.id" :action="actions.DELETE_SAMPLE" :callback="slotProps.search"/>

              </div>

            </div>

          </template>

        </Table>

      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { reactive, onMounted, computed, ref } from 'vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/SamplesEnums.ts";
import Table from '@/components/custom/Table.vue';
import DeleteRecord from '@/components/custom/DeleteRecord.vue';
export default {
  name: 'SampleList',
  components : {
    Table,
    DeleteRecord
  },
  setup(){
    onMounted(() => {
      setCurrentPageBreadcrumbs("Samples",[
        {title : "Samples List",to : "/samples/list"}
      ]);
    });

    const actions = computed(() => {
      return Actions;
    })



    const columns = [
      {name : 'code', title : 'Product Code', sortable : true, sort : null, searchable : true},
      {name : 'type', title : 'Product Type', sortable : false, sort : null, searchable : false},
      {name : 'name', title : 'Product Name', sortable : true, sort : 'asc', searchable : true},
      {name : 'actions', title : 'Edit / Delete',sortable : false, sort : null, searchable : false}
    ]

    return {
      columns,
      actions
    }

  }
}
</script>
