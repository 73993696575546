
import { reactive, onMounted, computed, ref } from 'vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/SamplesEnums.ts";
import Table from '@/components/custom/Table.vue';
import DeleteRecord from '@/components/custom/DeleteRecord.vue';
export default {
  name: 'SampleList',
  components : {
    Table,
    DeleteRecord
  },
  setup(){
    onMounted(() => {
      setCurrentPageBreadcrumbs("Samples",[
        {title : "Samples List",to : "/samples/list"}
      ]);
    });

    const actions = computed(() => {
      return Actions;
    })



    const columns = [
      {name : 'code', title : 'Product Code', sortable : true, sort : null, searchable : true},
      {name : 'type', title : 'Product Type', sortable : false, sort : null, searchable : false},
      {name : 'name', title : 'Product Name', sortable : true, sort : 'asc', searchable : true},
      {name : 'actions', title : 'Edit / Delete',sortable : false, sort : null, searchable : false}
    ]

    return {
      columns,
      actions
    }

  }
}
